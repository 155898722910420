
<template>
  <div class="cooperation">
    <!-- <div class="main-text" v-html="detailsdata.content"></div> -->
    <div class="partners-detail">
      <ul>
        <li v-for="item in cooperationList" :title="item.title">
           <el-image
     
      :src="global.imgUrl +item.thumb"
    @click="goLinkUrl(item)"
      ></el-image>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { getdetails,getCooperationlist } from "@/api";
export default {
  name: "cooperation",
  data() {
    return {
      cooperationList: {},
    };
  },
  methods: {
    async start() {
      await getCooperationlist().then((res) => {
        console.log(res)
        this.cooperationList = res.data
      });
    },
    goLinkUrl(item){
window.open(item.linkUrl, "_blank");
    }
  },
  mounted() {
    this.start();
  },
};
</script>

<style lang="less" scoped>
.main-text /deep/span{
  text-wrap: pretty !important;
}
.partners-detail {
  background: #fff;
  padding: 20px;
   ul {
    display: flex;
    flex-wrap: wrap;
    li {
      
    width: 33.3%;
    position: relative;
    margin-left: -1px;
    margin-bottom: -1px;
 transition: all .3s;
      //  overflow: hidden;
    .el-image:hover{
      cursor: pointer;
   box-shadow: 0 10px 20px 0 rgba(0,23,43,.1)
    }
}

  }
}
.main-text {
  margin: 20px 0;
  line-height: 34px;
  /deep/img {
    margin: 0 auto !important;
  }
  /deep/p {
    color: #464646 !important;
    font-size: 16px !important;
    line-height: 1.75rem !important;
    font-family: "PINGFANG-REGULAR" !important;
    span {
      color: #464646 !important;
      font-size: 16px !important;
      line-height: 1.75rem !important;
      font-family: "PINGFANG-REGULAR" !important;
    }
  }
}
</style>